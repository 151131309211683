
export const APP_NAME = `Hashpoint Research`
export const APP_NAME_0 = `Hashpoint `
export const APP_NAME_1 = `Research`

export const NAV_HOME = "Home"
export const NAV_DASHBOARD = "Dashboard"
export const NAV_ADMIN = "Admin"
export const NAV_GOVCOUNCIL = "Governing Council"
export const NAV_FORUM = "Forum"
export const NAV_FOUNDATIONS = "Hedera Network"
export const NAV_CHARTBOOK = "Chartbook"
export const NAV_MODEL = "Valuation Model"
export const NAV_BLOG = "Blog"
export const NAV_PROFILE = "Profile"
export const NAV_SERVICES = "Services";
export const NAV_ROADMAP = "Roadmap";
export const NAV_SUPPORT = "Support"
export const NAV_ACCOUNT = "Account"
export const NAV_HBARDIST = "HBAR Distribution"
export const NAV_NETWORK_DATA = "Network Data"
export const NAV_REVENUE = "Revenue"
export const NAV_STAKING_ECONOMICS = "Staking Economics"
export const NAV_DEFI_ON_HEDERA = "Defi On Hedera"
//  export const NAV_ = ""

export const LBL_HBAR = "HBAR"
export const LBL_HBAR_SYMBOL = "ℏ"
export const LBL_GIGABAR_SYMBOL = "Gℏ"
export const LBL_MEGABAR_SYMBOL = "Mℏ"
export const LBL_KILOBAR_SYMBOL = "kℏ"
export const LBL_MILLIBAR_SYMBOL = "mℏ"
export const LBL_MICOBAR_SYMBOL = "μℏ"
export const LBL_TINYBAR_SYMBOL = "tℏ"
export const LBL_TPS = "TPS"
export const LBL_TRANSACTIONS = "Transactions"
export const LBL_ACCOUNTS = "Accounts"
export const LBL_HBAR_STATISTICS = "HBAR Statistics"
export const LBL_ACCOUNT_TOPICS = "Accounts and Topics"
export const LBL_MONTHLY = "Monthly"
export const LBL_YEARLY = "Yearly"
export const LBL_READ_TERMS = "I have read and agree to the"
export const LBL_TERMS_CONDITIONS = "Terms and Conditions"
export const LBL_USD = "USD"
export const LBL_USD_PARENS = "(USD)"

export const LBL_ACCOUNT_LOOKUP = "Account Lookup"
export const LBL_ACCOUNT_SETUP = "Account Setup"
export const LBL_STAKING_REWARDS_COST = "Staking Rewards Cost Basis"
export const LBL_ACCOUNT_ID = "Account Id"
export const LBL_TRUE_VALUE = "Yes"
export const LBL_FALSE_VALUE = "No"
export const LBL_NO_RECORDS_MESSAGE = "No records found"
export const LBL_GET_STARTED = "Get Started with a Free Trial"
export const LBL_FINISH_SIGNING_UP = "Finish Signing Up"
export const LBL_CREATE_ACCOUNT = "Start Free Trial"

export const LBL_FIRST_NAME = "First Name"
export const LBL_LAST_NAME = "Last Name"
export const LBL_USER_NAME = "User Name"
export const LBL_EMAIL = "Email"
export const LBL_PASSWORD = "Password"
export const LBL_DISCORD = "Discord"

//  export const LBL_ = ""

export const TXT_FREE_TRIAL_LENGTH = "7"

export const TXT_TWITTER_X = "𝕏";
export const TXT_SUCCESS = "Success";
export const TXT_ERROR = "Error";
export const TXT_STD_ERROR_MESSAGE = "Error - something went wrong.";
export const TXT_RESENT_VERIFICATION_EMAIL = "We have resent the verification email to the email address listed on the account."
export const TXT_GENERIC_ERROR_MESSAGE = "Something went wrong. The error has been logged and will be reviewed."
export const TXT_DATA_SVC_PROVIDER_ERROR_MESSAGE = "We are investigating an issue with one of our data service providers.  Some data may be temporarily unavailable or incomplete."
export const TXT_DB_PH_TEXT="Ideas for improvements, or maybe something specific you'd like us to add. For support issues, email support@hashpoint.io."
export const TXT_CB_PH_TEXT="Hashpoint welcomes all feedback that helps improve the accuracy and transparency of HBAR holder data. Provide a short and verifiable explanation which supports your position. For support issues, email support@hashpoint.io."
export const TXT_FEEDBACK_SUCCESS_MESSAGE = "Thanks! Your feedback was successfully submitted and will be reviewed.  We do not provide responses to feedback.";
export const TXT_FEEDBACK_FAIL_MESSAGE = "Please try resubmitting your comment.";
export const TXT_ = "";

//  export const TXT_ = "";

export const PLAN_STATUS = {
    PENDING_ACTIVE: "0"
    , ACTIVE: "1"
    , PENDING_CANCEL: "2"
    , CANCELED: "3"
    , ACTIVE_AFTER_CANCEL: "4"
    , DECLINED: "5"
    , ABANDONED_CHECKOUT: "6"
};

export const DIRECT_MESSAGE = {
    WELCOME_MESSAGE: "1"
    , SUPPORT_REQUEST_ACK: "2"
    , NEW_BLOG_POST: "3"
    , UNSUCCESSFUL_CHECKOUT: "4"
};

export const APP = {
    UNIQUEID: "ac180aaa-0122-44f5-bfc4-4b6a79975708"
    , APP_NAME: APP_NAME
}