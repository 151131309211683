import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { SplitButton } from 'primereact/splitbutton';
import { Toast } from 'primereact/toast';
import { Avatar } from 'primereact/avatar';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { left, capitalizeFirstChar, delay } from './../../shared/fn';
import { TOAST_LIFE_INFO } from './../../shared/options';

//import { BladeConnector, HederaNetwork, ConnectorStrategy } from '@bladelabs/blade-web3.js';
//import { useHashConnect } from "./../../components/hooks/useHashconnect";

const LogoutButton = (props) => {
    const { member, memberstack } = props;
    //const [member] = useState(props.member);
    //const [accountId, setAccountId] = useState(null);
    //const [showBlade, setShowBlade] = useState(false);
    const [showWalletSelector, setShowWalletSelector] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [hideWarning, setHideWarning] = useState(false);
    //const [wallet, setWallet] = useState(false);
    //const [xxx, setXxx] = useState(false);
    //const memberstack = props.memberstack;
    //const [appState, appActions] = useAppState();
    //const [authState, authActions] = useAuth();
    const toast = useRef(null);
    const showedWelcomeRef = useRef(false);
    const navigate = useNavigate();
    //const network = "mainnet";

    const walletRef = useRef();

    //const {
        //connectToExtension,
        //disconnect,
        //pairingData,
        //availableExtension,
        //network = "mainnet",
        //pairingString,
        //sendTransaction,
      //} = useHashConnect();

    //console.log(`pairingData`, pairingData)
    //const handleShowBlade = () => setShowBlade(true);
    //const handleCloseBlade = () => setShowBlade(false);

    const handleLogoutClick = async (e) => {
        const res = await memberstack.logout();
        sessionStorage.setItem('__sak:xq7gytre2j', true);
        navigate("/login", { state: { memberId: res.data.id, isLogout: true } });
    }
    
    const handleProfileClick = async (e) => {
        navigate("/profile");
    }

    // const showSelector = () => {
    //     setShowWalletSelector(true);
    // }

    const handleSelectorClick = (wallet) => {
        //setWallet(wallet);
        walletRef.current = wallet;
        setShowWalletSelector(false);

        const hideWarning = hideThirdPartyConnect();
        if(!hideWarning) {
            setShowWarning(true);
        }
        else {
            initWallet();
        }            
    }

    const initWallet = () => {
        const wallet = walletRef.current;

        if(wallet === 'blade') {
            initBlade();
        }
        else if(wallet==='hashpack') {
            initHashpack();
        }

        warningDialogHide();
    }

    const initBlade = async () => {        
        // const bladeConnector = await BladeConnector.init(
        //     ConnectorStrategy.AUTO, // preferred strategy is optional 
        //     { // dApp metadata options are optional, but are highly recommended to use
        //       name: "Hashpoint Research",
        //       description: "Hello Wealth",
        //       url: "https://hashpoint.io/",
        //       icons: ["https://hashpoint.netlify.app/h.png"]
        //     }
        //   );

        // const params = {
        //     network: HederaNetwork.Mainnet,
        //     dAppCode: "SomeAwesomeDApp" // optional while testing, request specific one by contacting us
        // }

        //const pairedAccountIds = await bladeConnector.createSession(params);
        // retrieving the first available signer to perform all the Hedera operations
        //const bladeSigner = await bladeConnector.getSigners()[0];

        // create session with optional parameters.
        //await bladeSigner.createSession(params);

        // bladeSigner object can now be used.
        //const accountId = bladeSigner.getAccountId();

        //setAccountId(accountId);
        //console.log({ accountId });
        //handleCloseBlade();
    };

    const initHashpack = async() => {
        //connectToExtension();
    }

    const blade = (
        <div className={`blade`} onClick={(e) => handleSelectorClick('blade')}>
            <figure className="wallet-selector m-4 hand">
                <img src="https://www.bladewallet.io/wp-content/uploads/2022/04/BladeWalletWhite.svg" alt="Blade Wallet" />
            </figure>
        </div>
    )

    // const metamask = (
    //     <div className={`metamask`} onClick={(e) => handleSelectorClick('metamask')}>
    //         <figure className="wallet-selector m-4 hand">
    //             <img src="https://freelogopng.com/images/all_img/1683020860metamask-logo-white.png" alt="Metamask logo" />
    //         </figure>
    //     </div>
    // )

    // const coinbase = (
    //     <div>
    //         <div className="coinbase" onClick={(e) => handleSelectorClick('coinbase')}>
    //             <figure className="wallet-selector m-4 hand">
    //                 <img src="https://logodownload.org/wp-content/uploads/2021/04/coinbase-logo-1-2048x430.png" alt="Coinbase logo" />
    //             </figure>
    //         </div>
    //     </div>
    // )

    const hashpack = (
        <div>
            <div className="hashpack" onClick={(e) => handleSelectorClick('hashpack')}>
                <figure className="wallet-selector m-4 hand">
                    <img src="https://uploads-ssl.webflow.com/61ce2e4bcaa2660da2bb419e/61cf5cc71c9324950d7e071d_logo-colour-white.svg" alt="HashPack Wallet" />
                </figure>
            </div>
        </div>
    )

    const buttonOptions = () => {
        return [
            // {
            //     label: `Connect Wallet`,
            //     icon: 'pi pi-wallet',
            //     command: () => {
            //         showSelector();
            //     }
            // },
            {
                label: `Profile`,
                icon: 'pi pi-user',
                command: () => {
                    handleProfileClick();
                }
            },            
            {
                label: `Log out`,
                icon: 'pi pi-sign-out',
                command: () => {
                    handleLogoutClick();
                }
            }            
        ];
    }

    const onSelectorDialogHide = () => {
        setShowWalletSelector(false);
    }

    const warningDialogHide = () => {
        setShowWarning(false);
    }

    const warningDialogFooter = () => {
        return (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={() => warningDialogHide()} className="p-button-text" />
                <Button label="Connect Wallet" icon="pi pi-check" onClick={() => initWallet() } autoFocus />
            </div>
        );
    }

    const hideThirdPartyConnect = () => {
        const hide = localStorage.getItem('__sak:hide_third_party_connect') ?? false;
        return hide;
    }

    const handleCheckChange = (e) => {
        setHideWarning(e.checked);
        localStorage.setItem('__sak:hide_third_party_connect', e.checked);
    }

    const getAvatarStyle = () => {
        const arr = member?.customFields.hsl ? member?.customFields.hsl.split(',#') : 'hsl(0, 100%, 100%),#555';
        return [arr[0], '#'+arr[1]];
    }

    const avatarStyle = getAvatarStyle();
    
    const setVisibility = useCallback(async() => {
        const wrapper = document.getElementById('wrapper');
        const btn = document.getElementById('logout');

        if(member) {
            wrapper.style.visibility = "visible";
            btn.style.visibility = "visible";
        }

    }, [member])

    const showLoginMsg = useCallback(async() => {
        //console.log('showLoginMsg() entered', new Date().toLocaleString());
        if(showedWelcomeRef.current) return;
        showedWelcomeRef.current = true;

        let summary = true ? 'Welcome' : 'Welcome back';

        if(member.customFields.firstname) {
            summary += ' ' + member.customFields.firstname;
        }
        //console.log('showLoginMsg() before delay', new Date().toLocaleString());
        await delay(2000);
        //console.log('showLoginMsg() after delay', new Date().toLocaleString());

        toast.current?.show({
            severity: `info`,
            summary: `${summary}!`,
            detail: `Logged in as ${member.auth.email}`,
            life: TOAST_LIFE_INFO,
            sticky: false
        });
    }, [member.customFields.firstname, member.auth.email]);

    useEffect(() => {
        setVisibility();

        if(member?.auth.isLogin) {
            showLoginMsg();
        }
    } , [setVisibility, member, showLoginMsg]);

    return (
        <div>        
            <Toast ref={toast} position= "bottom-right"></Toast>
            <div id={`wrapper`} style={{visibility: 'hidden'}}>
                <SplitButton
                    id='logout'
                    name='logout'
                    label={`${member?.auth?.email}` }
                    icon={
                        <Avatar 
                            label={`${capitalizeFirstChar(left(member?.auth?.email, 1))}`} 
                            shape="circle"
                            size="large"
                            style={{ backgroundColor: avatarStyle[0], color: avatarStyle[1] }}
                        />
                    }
                    model={ buttonOptions() }
                    style={{visibility: 'hidden'}}
                />
            </div>

            <Dialog 
                header="Connect Wallet" 
                visible={showWalletSelector} 
                style={{ width: '40vw' }} onHide={() => onSelectorDialogHide()}>
                <div className={`flex justify-content-center`}>
                    { blade }                    
                    { hashpack }
                </div>
            </Dialog>

            <Dialog header="Connect Wallet"
                visible={showWarning}
                style={{ width: '35vw' }}
                onHide={() => warningDialogHide()}
                footer={warningDialogFooter}>

                <div className={`flex justify-content-center warning`}>
                    The service you are attempting to access is a crypto wallet operated by a third-party. This service is provided for your information and convenience only, and we do not 
                    endorse the content or behavior of any third-party service. If you use this service you leave our website. Hashpoint Research has no control over the content of the 
                    service including specifically your wallet and therefore we are not responsible for this services' content, behavior or availability. Please refer to your wallet's applicable 
                    Terms of Service and Privacy policy.
                </div>
                <div style={{padding:'1rem 0 0 0'}}>
                    <Checkbox inputId="cb1" checked={hideWarning} onChange={handleCheckChange} />&nbsp;&nbsp;
                    <label htmlFor="cb1">Do not show this again</label>
                </div>
            </Dialog>
        </div>      
    );
  }

  export default LogoutButton