import React, { useState, useEffect, useCallback } from 'react';   // useRef
import { DataTable  } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Editor } from 'primereact/editor';
import { paginatorTemplate } from './../../shared/options';
import { delay, nanoToLocalDate } from './../../shared/fn';
import { getMemberDirectMessages } from './../../api/api'
import { LBL_FINISH_SIGNING_UP } from './../../shared/constants';
import { updateDmStatus } from './../../api/api';
import { useQuery } from '@tanstack/react-query';

import './_dm.scss';

const DirectMessage = (props) => {
    const { title, unreadCount, onExpand, member } = props;
    const [expandedRows, setExpandedRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [recs, setRecs] = useState(false);
    //const [xxx, setXxx] = useState(false);

    const dateActiveTemplate = (rowData) => {
        const dt = rowData.dateactive ? nanoToLocalDate(rowData.dateactive) : '';
        return <span className={`${rowData.statusid===0 ? 'bold' : ''}`}>{dt}</span>;
    }

    const subjectTemplate = (rowData) => {
        return <span className={`${rowData.statusid===0 ? 'bold' : ''}`}>{rowData.subject}</span>;
    }

    const messageTemplate = (rowData) => {
        const message = rowData.message
            .replace('$fname$', rowData.firstname)
            .replace('$title$', rowData.article_title)
            .replace('$finish_signing_up$', LBL_FINISH_SIGNING_UP);

        return <Editor headerTemplate={<div></div>} value={`${message}`} />;
    }

    const fromTemplate = (rowData) => {
        return <span className={`${rowData.statusid===0 ? 'bold' : ''}`}>{rowData.from}</span>;
    }

    const getColumnTemplate = (column) => {
        let template;

        switch (column) {
            case "dateactive":
                template = dateActiveTemplate;
                break;
            case "subject":
                template = subjectTemplate;
                break;
            case "message":
                template = messageTemplate;
                break;
            case "from":
                template = fromTemplate;
                break;
            default:
        }

        return template;
    }    

    const onRowExpand = async(event) => {
        if(event.data.statusid === 1) return;

        event.data.statusid = 1;
        updateDmStatus(event.data.memberdmid, event.data.uniqueid, '1');

        await delay(500);

        let newCount = unreadCount - 1;
        if(newCount < 0) {
            newCount = 0;
        }

        onExpand(newCount);
    }

    const allowExpansion = (rowData) => {
        return true;
    };

    const collapseAll = () => {
        setExpandedRows(null);
    }

    const handleExpandRow = (data) => {
        collapseAll();
        setExpandedRows(data);
    }

    const rowExpansionTemplate = (data) => {
        const recs = [data];

        return (
            <div className="subtable-no-header">
                <DataTable value={recs}>
                    <Column field="message" body={getColumnTemplate("message")}></Column>
                </DataTable>
            </div>
        );
    }

    const paginatorLeft = <></>;
    const paginatorRight = <></>;

    const getDirectMessages = useCallback(async (query) => {
        setLoading(true);
        const resp=await getMemberDirectMessages(query.queryKey[1])
            .then(function (response) {                    
                return response;
        })

        setLoading(false);
        return resp.data.data;          
    }, []);

    const handleRecords = useCallback((recs) => {
        setRecs(recs);
    }, []);

    const { status, data, error } = useQuery({ 
        queryKey: [`get-member-messages`, member?.customFields?.uniqueid],
        queryFn: getDirectMessages,
        staleTime: 3600000,
        refetchInterval:3600000,
        enabled: member !==null
    });

    useEffect(() => {   
        if(status==="success") {
            console.log(data)
            handleRecords(data);
        }
        else {
            console.log(error)
        }
    }, [data, status, handleRecords, error]);

    return (   
        <div className="p-1 flex flex-column flex-auto">
            <div className="title font-weight-500 pb-3">{title}</div>
            <div>
                <DataTable 
                    value={recs} 
                    size="small"
                    expandedRows={expandedRows}
                    rowExpansionTemplate={rowExpansionTemplate}
                    onRowToggle={(e) => handleExpandRow(e.data)}
                    onRowExpand={onRowExpand}
                    header={null}
                    showGridlines
                    stripedRows
                    dataKey={`memberdmid`}
                    sortField="received" 
                    sortOrder={-1}
                    rows={10}
                    resizableColumns 
                    columnResizeMode="fit"
                    scrollable 
                    scrollHeight="30rem"
                    breakpoint="960px"
                    paginator
                    paginatorTemplate={paginatorTemplate()}
                    paginatorLeft={paginatorLeft} 
                    paginatorRight={paginatorRight}
                    loading={loading}
                    emptyMessage = {`No new messages`}
                    className={`messages-table`}>
                    <Column expander={allowExpansion} style={{ width: '1rem' }} />
                    <Column field="from" header="From" body={getColumnTemplate("from")} sortable className={``} style={{ width: '25%' }}></Column>
                    <Column field="subject" header="Subject" body={getColumnTemplate("subject")}  sortable className={``} style={{ width: '50%' }}></Column>
                    <Column field="dateactive" header="Received" body={getColumnTemplate("dateactive")} sortable className={``} style={{ width: '25%' }}></Column>
                    <Column field="statusid" header="Status" className={`hidden`}></Column>
                </DataTable>
            </div>               
        </div>        
    );
  }

  export default DirectMessage