import {chartProps} from './fn';

export const TOAST_LIFE_SUCCESS = 9000;
export const TOAST_LIFE_INFO = 5000;
export const TOAST_LIFE_ERROR = 15000;
export const DEFAULT_RPP = 10;

export const getRowsPerPage = () => {
    return [DEFAULT_RPP, 25, 50];
}

export const paginatorTemplate = () => {
    return "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown";
}

export const barPlotOptions=(data, label, bgColor, order, animation)=> {
    if(!animation) animation = 0;
    
    return {   
        type:'bar',
        label: label ?? '',
        backgroundColor: bgColor ?? 'transparent',
        data: data,
        order: 2,
        animation: {
            duration: animation,
        },
        options: {
            plugins: {
              customCanvasBackgroundColor: {
                color: '#665191',
              }
            }
        },
    }
}

export const linePlotOptions=(data, label, lineColor, order, animation)=> {
    if(!animation) animation = 0;
    const color = lineColor ? lineColor : (order===0 || !order) ? '#ffa600' : '#ff7c43';
    const props=chartProps();
    
    return {   
        type:'line',
        label: label,
        borderColor: color,
        borderWidth: props[2],
        fill: true,
        pointRadius:props[2],
        pointHoverRadius: props[2],
        tension: .4,
        data: data,
        order: 1,
        animation: {
            duration: animation,
        }
    }
}

export const scatterPlotOptions=(data, label, plotColor, order, animation)=> {
    if(!animation) animation = 0;
    const color = plotColor ? plotColor : (order===0 || !order) ? '#ffa600' : '#ff7c43';
    const props=chartProps();
    
    return {   
        type:'scatter',
        label: label ?? '',
        fill: true,
        borderColor: color,
        borderWidth: props[2],        
        pointRadius: props[2],
        showLine:true,
        tension: .4,
        data: data,
        animation: {
            duration: animation,
        },
        order: 1
    }
}

export const chartOptions=(indexAxis, xLabel, yLabel, stacked)=>{
    if(!stacked) stacked = false;

    const props=chartProps();
    
    return  {
        indexAxis: indexAxis ?? 'x',
        maintainAspectRatio: false,
        responsive:true,
        aspectRatio: .7,
        plugins: {
            tooltip:{
                backgroundColor:'#0ee12',
                bodySpacing:7,
                titleFont: { size: props[1] },
                bodyFont: { size: props[1] },
                footerFont: { size: props[1] },
            },
            legend: {
                labels: {
                    color: '#fff',                    
                    font: {
                        size: props[0],
                        family: 'Jost'
                    }
                }
            }
        },
        scales: {
            x: {
                stacked: stacked,
                offset: true,
                title: {
                    display: true,
                    text: xLabel,
                    color: '#fff',
                    font: {
                        size: props[0],
                        family: 'Jost'
                    }
                },
                ticks: {
                    color: '#dddddd',
                    beginAtZero: true,
                    stepSize: 20,
                    font: {
                        size: props[0],
                        family: 'Jost'
                    }
                },
                grid: {
                    color: '#9E9E9E',
                    lineWidth: props[3],
                }
            },
            y: {
                stacked: stacked,
                offset: false,
                title: {
                    display: true,
                    text: yLabel,
                    color: '#fff',
                    font: {
                        size: props[0],
                        family: 'Jost'
                    },
                },
                ticks: {
                    color: '#dddddd',
                    beginAtZero: true,
                    
                    font: {
                        size: props[0],
                        family: 'Jost'
                    }
                },
                grid: {
                    color: '#9E9E9E',
                    lineWidth: props[3],
                }
            }
        }
    }
}