import React from 'react';
import './../layout/_header.scss'

const Logo = () => {
    return (
        <>
            <div className="h-logo">
                <span></span>
                <div>H</div>
            </div>
        </>
    );
  }

  export default Logo