import { createStore, createHook } from 'react-sweet-state';

const Store = createStore({
    // value of the store on initialisation
    initialState: {
      data: {},
      nonce: '78b9a5ff-96cf-4757-962e-2e84c9f742ad',
      sessionId: null,
      status: null
    },
    // actions that trigger store mutation
    actions: {
      authchange:
        (data, sessionId, status) =>
            ({ setState, getState }) => {
            // mutate state synchronously
                setState({
                    data: data,
                    sessionId: sessionId,
                    status: status
                });
            },
    },
    // optional, unique, mostly used for easy debugging
    name: 'session-state',
  });
  
  export const useSession = createHook(Store);