import React, { useEffect, useCallback} from 'react';
import { useMemberstackModal } from "@memberstack/react";
import { Button } from 'primereact/button';
import { useNavigate } from "react-router-dom";
import { openLoginModal, delay } from '../../shared/fn';

const LoginButton = (props) => {
    const { openModal, hideModal } = useMemberstackModal();
    const navigate = useNavigate();

    const setVisibility = useCallback(async() => {
        const wrapper = document.getElementById('wrapper');
        const btn = document.getElementById('login');

        await delay(1000);
        
        if(!props?.member) {
            wrapper.style.visibility = "visible";
            btn.style.visibility = "visible";
            btn.style.paddingLeft = "1rem";
            btn.style.paddingRight = "1rem";
        }
    }, [props?.member])

    useEffect(() => {
        setVisibility();
    } , [setVisibility]);

    return (
        <div id="wrapper" style={{visibility: 'hidden'}}>
            <Button
                id='login'
                name='login'
                label={`Login`}
                onClick={(e) => openLoginModal(openModal, hideModal, navigate, null)}
                className={`p-button-outlined p-button login`}
                tooltip={``}
                style={{visibility: 'hidden'}}
            />
        </div>        
    );
  }

  export default LoginButton