import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from "react-router-dom";
import { Toast } from 'primereact/toast';
import { Badge } from 'primereact/badge';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Toolbar } from 'primereact/toolbar';
import { Link } from 'react-router-dom';
import Logo from '../components/logo-h';
import LogoutButton from '../components/buttons/logoutButton';
import LoginButton from '../components/buttons/loginButton';
import SignupButton from '../components/buttons/signupButton';
import DirectMessage from '../components/messages/dm';
import { getMemberUnreadDmCount } from './../../src/api/api';
//import { } from './../shared/options';
import { APP_NAME_0, APP_NAME_1, LBL_GET_STARTED, LBL_FINISH_SIGNING_UP } from './../shared/constants';

import { useQuery } from '@tanstack/react-query';

import './_header.scss';

const Header = (props) => {    
    const { member, memberstack, isLogin } = props;
    const op = useRef(null);
    const toast = useRef(null);
    const [unreadCount, setUnreadCount] = useState();
    const [isDashboard, setIsDashboard] = useState(false);
    const location = useLocation();

    

    console.log('isDashboard', isDashboard)
    //const [xxx, setXxx] = useState(false);

    useEffect(() => {
        setIsDashboard(location.pathname.toLowerCase() === '/dashboard')
     }, [location.pathname]);

    const badge = (<i className="pi pi-bell mr-4 p-text-secondary p-overlay-badge hand" style={{ fontSize: '1.5rem' }} onClick={(e) => op.current.toggle(e)}>
        <Badge value={ unreadCount } severity="info" ></Badge></i>
    );

    const handleRowExpand = (count) => {
        setUnreadCount(count);
    }

    const rightContents = (
        (member) ?
            <>
                
                { badge }
                <LogoutButton 
                    className={` logout-button`}
                    member={ member } 
                    memberstack={ memberstack }
                    isLogin={ isLogin }
                />
            </>            
            :
            <>                
                <LoginButton
                    className={` login-button`}
                />
            </>            
    );    

    const centerContents = (
        ((member && member?.permissions?.length > 0)) ? 
            <div style={{textAlign: 'left', width: '33%', border: '0px solid red', padding: '.25rem 0 0 0'}}  className={`is-dashboard-${isDashboard}`}>
                <img src='./powered-by-hgraph.png' alt='powered by hgraph x' style={{width: '65%'}} />
            </div>            
        :
            <>
                <div style={{display: 'flex', justifyContent: 'left', width: '33%', border: '0px solid red', position: 'relative', left: '-2rem', padding: '.25rem 0 0 0'}}  className={`is-dashboard-${isDashboard}`}>
                    <img src='./powered-by-hgraph.png' alt='powered by hgraph' style={{width: '55%'}} />
                </div>

                {/* <SignupButton
                    label={!member ? LBL_GET_STARTED : LBL_FINISH_SIGNING_UP}
                    renderAsLink= {false}
                    disabled={false}
            /> */}
        </>
        
    );

    const leftContents = (
        <>
            <div>                
                <Link to="/" >
                    <div>
                        <div className={`app-name flex`} >
                            <Logo />
                            <div style={{paddingLeft: '1rem'}} className={`app-name-0`}>{APP_NAME_0} {APP_NAME_1}</div>
                        </div>
                        
                    </div>                         
                </Link>
            </div>
        </>
    );

    const getDirectMsgsUnreadCount = useCallback(async (query) => {        
        const resp=await getMemberUnreadDmCount(query.queryKey[1])
            .then(function (response) {                    
                return response;
        })

        return resp.data.response;          
    }, []);

    const handleRecords = useCallback((count) => {
        setUnreadCount(count);
    }, []);

    const { status, data } = useQuery({ 
        queryKey: [`get-member-dm-unread-count`, member?.customFields?.uniqueid],
        queryFn: getDirectMsgsUnreadCount,
        staleTime: 3600000,
        refetchInterval:3600000,
        enabled: true
    });

    useEffect(() => {   
        if(status==="success") {
            handleRecords(data);
        }
    }, [data, status, handleRecords]);

    return (
        <header>
            <Toast ref={toast} position="bottom-right" />
            <OverlayPanel ref={op} id="messages_overlay" style={{width: '55rem', marginTop: '1rem'}} className={``}>
                <div>
                    <DirectMessage title={`Messages`} member={ member } unreadCount={ unreadCount } onExpand={ handleRowExpand } />
                </div>
            </OverlayPanel>
            <Toolbar start={leftContents} center={ centerContents } end={ rightContents } />
        </header>
    );
};

export default Header;
