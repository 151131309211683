import React, { useState, useCallback } from 'react';
import { Link } from "react-router-dom";
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { MemberstackProtected } from "@memberstack/react";
import { getSubDirectory, toggleSidebar, getElementsByClass, removeClass, addClass, replaceAll, delay, isAdmin } from '../shared/fn';
import * as cnst from '../shared/constants';
import './_menu.scss';

const NavMenu = (props) => {
    const { permissions } = props;

    //console.log('permissions', permissions);

    const subdir = getSubDirectory();
    const [activeMenuIndex, setActiveMenuIndex] = useState(-1);
    const [collapsed, setCollapsed] = useState(false);

    let index;

    const toggleSidenav = useCallback((isCollapsed) => {
        setCollapsed(!collapsed);
        
        toggleSidebar('nav-text');
        const sidebar = getElementsByClass("sidebar")[0];
        
        localStorage.setItem('__sak:TzJtn1OaqW', !collapsed);

        if(collapsed) {
            removeClass(sidebar, 'collapsed');
            addClass(sidebar, 'expanded');
        }
        else {
            removeClass(sidebar, 'expanded');
            addClass(sidebar, 'collapsed');
        }
    }, [collapsed]);
    
    const handleMenuClick = async(idx) => {
        setActiveMenuIndex(idx);
        await delay(250)
        sessionStorage.setItem('__sak:path', getSubDirectory());
    }

    let items = [
        {
             label: '',
             template: (item) => {
                 return (
                     <div className="menu-spacer">&nbsp;</div>
                 );
             }
        },

        {
            label: cnst.NAV_DASHBOARD,
            template: (item) => {
                index = 0;
                return (
                    <div className="nav-item flex align-items-center cursor-pointer">
                        <Link to={replaceAll(item.label.toLowerCase(), ' ', '')} onClick={() => handleMenuClick(index)} 
                            className={`nav-item-link ${activeMenuIndex===index && subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>
                            <span title={item.label} className={`icon icon-nav icon-dashboard ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`} />
                            <span className={`nav-text ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>{item.label}</span>
                        </Link>                                    
                    </div>
                );
            }
        },

        {
            label:cnst.NAV_NETWORK_DATA,
            template: (item) => {
                index = 1;
                return (
                    <div className="nav-item flex align-items-center cursor-pointer">                        
                        <Link to={replaceAll(item.label.toLowerCase(), ' ', '')} onClick={() => handleMenuClick(index)} 
                            className={`nav-item-link ${activeMenuIndex===index && subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>
                                <span title={item.label} className={`icon icon-nav icon-chartbook ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`} />
                                <span className={`nav-text ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>{item.label}</span>
                        </Link>
                    </div>
                );
            }
        },

        {
            label: cnst.NAV_REVENUE,
            template: (item) => {
                index = 2;
                return (
                    <div className="nav-item flex align-items-center cursor-pointer">                        
                        <Link to={replaceAll(item.label.toLowerCase(), ' ', '')} onClick={() => handleMenuClick(index)} 
                            className={`nav-item-link ${activeMenuIndex===index && subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>
                                <span title={item.label} className={`icon icon-nav icon-chartbook ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`} />
                                <span className={`nav-text ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>{item.label}</span>
                        </Link>
                    </div>
                );
            }
        },

        {
            label: cnst.NAV_STAKING_ECONOMICS,
            template: (item) => {
                index = 3;
                return (
                    <div className="nav-item flex align-items-center cursor-pointer">                        
                        <Link to={replaceAll(item.label.toLowerCase(), ' ', '')} onClick={() => handleMenuClick(index)} 
                            className={`nav-item-link ${activeMenuIndex===index && subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>
                                <span title={item.label} className={`icon icon-nav icon-chartbook ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`} />
                                <span className={`nav-text ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>{item.label}</span>
                        </Link>
                    </div>
                );
            }
        },

        {
            label: cnst.NAV_DEFI_ON_HEDERA,
            template: (item) => {
                index = 4;
                return (
                    <div className="nav-item flex align-items-center cursor-pointer">                        
                        <Link to={replaceAll(item.label.toLowerCase(), ' ', '')} onClick={() => handleMenuClick(index)} 
                            className={`nav-item-link ${activeMenuIndex===index && subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>
                                <span title={item.label} className={`icon icon-nav icon-chartbook ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`} />
                                <span className={`nav-text ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>{item.label}</span>
                        </Link>
                    </div>
                );
            }
        },

        {
            label: cnst.NAV_HBARDIST,
            template: (item) => {
                index = 5;
                return (
                    <div className="nav-item flex align-items-center cursor-pointer">                        
                        <Link to={replaceAll(item.label.toLowerCase(), ' ', '')} onClick={() => handleMenuClick(index)} 
                            className={`nav-item-link ${activeMenuIndex===index && subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>
                                <span title={item.label} className={`icon icon-nav icon-chartbook ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`} />
                                <span className={`nav-text ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>{item.label}</span>
                        </Link>
                    </div>
                );
            }
        },

        {
            label: cnst.NAV_MODEL,
            template: (item) => {
                index = 6;
                return (
                    <div className="nav-item flex align-items-center cursor-pointer">                        
                        <Link to={replaceAll(item.label.toLowerCase(), ' ', '')} onClick={() => handleMenuClick(index)} 
                            className={`nav-item-link ${activeMenuIndex===index && subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>
                                <span title={item.label} className={`icon icon-nav icon-analytics ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`} />
                                <span className={`nav-text ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>{item.label}</span>
                        </Link>
                    </div>
                );
            }
        },

        {
            label: cnst.NAV_BLOG,
            template: (item) => {
                index = 7;
                return (
                    <div className="nav-item flex align-items-center cursor-pointer">                        
                        <Link to={replaceAll(item.label.toLowerCase(), ' ', '')} onClick={() => handleMenuClick(index)} 
                            className={`nav-item-link ${activeMenuIndex===index && subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>
                                <span title={item.label} className={`icon icon-nav icon-blog ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`} />
                                <span className={`nav-text ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>{item.label}</span>
                        </Link>
                    </div>
                );
            }
        },
        
        {
            label: cnst.NAV_GOVCOUNCIL,
            template: (item) => {
            index = 8;
                return (
                    <div className="nav-item flex align-items-center cursor-pointer">                        
                        <Link to={replaceAll(item.label.toLowerCase(), ' ', '')} onClick={() => handleMenuClick(index)} 
                            className={`nav-item-link ${activeMenuIndex===index && subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>
                                <span title={item.label} className={`icon icon-nav icon-govcouncil ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`} />
                                <span className={`nav-text ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>{item.label}</span>
                        </Link>
                    </div>
                );
            }
        },

        {
            label: cnst.NAV_ACCOUNT,
            items:[
                {
                    label: cnst.NAV_SERVICES,
                    template: (item) => {
                        index = 9;
                        return (
                            <div className="nav-item nav-sub-item flex align-items-center cursor-pointer">                        
                                <Link to={replaceAll(item.label.toLowerCase(), ' ', '')} onClick={() => handleMenuClick(index)} 
                                    className={`nav-item-link ${activeMenuIndex===index && subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>
                                        <span title={item.label} className={`icon icon-nav icon-calculator ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`} />
                                        <span className={`nav-text ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>{item.label}</span>
                                </Link>
                            </div>
                        );
                    }
                },        
                {
                    label: cnst.NAV_PROFILE,
                    template: (item) => {
                        index = 10;
                        return (
                            <div className="nav-item nav-sub-item flex align-items-center cursor-pointer">                        
                                <Link to={replaceAll(item.label.toLowerCase(), ' ', '')} onClick={() => handleMenuClick(index)} 
                                    className={`nav-item-link ${activeMenuIndex===index && subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>
                                        <span title={item.label} className={`icon icon-nav icon-profile ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`} />
                                        <span className={`nav-text ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>{item.label}</span>
                                </Link>
                            </div>
                        );
                    }
                },
                {
                    label: cnst.NAV_SUPPORT,
                    template: (item) => {
                        index = 11;
                        return (
                            <div className="nav-item nav-sub-item flex align-items-center cursor-pointer">                        
                                <Link to={replaceAll(item.label.toLowerCase(), ' ', '')} onClick={() => handleMenuClick(index)} 
                                    className={`nav-item-link ${activeMenuIndex===index && subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>
                                        <span title={item.label} className={`icon icon-nav icon-at ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`} />
                                        <span className={`nav-text ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>{item.label}</span>
                                </Link>
                            </div>
                        );
                    }
                },
             ],            
        },

        {
            label: cnst.NAV_ADMIN,
            template: (item) => {
                index = 13;
                return (
                    !isAdmin(permissions) 
                    ? <></> 
                    :
                     <MemberstackProtected allow={{
                        permissions: ["9703094d-7ef3-4293-994b-4aff487b5add"]
                    }}>
                        <div className="nav-item flex align-items-center cursor-pointer">
                            <Link to={replaceAll(item.label.toLowerCase(), ' ', '')} onClick={() => handleMenuClick(index)} 
                                className={`nav-item-link ${activeMenuIndex===index && subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>
                                    <span title={item.label} className={`icon icon-nav icon-admin ${activeMenuIndex===index && subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`} />
                                    <span className={`nav-text ${activeMenuIndex===index || subdir===replaceAll(item.label.toLowerCase(), ' ', '') ? 'selected' : ''}`}>{item.label}</span>
                                </Link>                                    
                        </div>
                    </MemberstackProtected>
                );
            }
        },

        {
            label: '',
            template: (item) => {
                return (
                    <div className="toggle">                        
                        <Button icon={`pi pi-angle-double-left`} className={`expanded no-bg no-border ${collapsed ? 'hidden' : ''}`} onClick={e=>toggleSidenav(e)}/>
                        <Button icon={`pi pi-angle-double-right`} className={`collapsed no-bg no-border ${collapsed ? '' : 'hidden'}`} onClick={e=>toggleSidenav(e)}/>
                    </div>
                );
            }
       },
    ]

    return ( 
            <div>
                <Menu className='nav-menu' model={items} multiple />
            </div> 
        );
  }

  export default NavMenu