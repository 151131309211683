import { createStore, createHook } from 'react-sweet-state';

const Store = createStore({
    // value of the store on initialisation
    initialState: {
      data: { message : null },
      fetchErrorCount: 0,
      appVersion: 0,
      maintMode: false,
      dmEnabled: true,
      latencyThreshold: 3,
    },

    // actions that trigger store mutation
    actions: {
        setLatencyThreshold: (threshold) =>
            ({ setState, getState }) => {
                setState({
                    latencyThreshold: threshold
                });
            },
        setMaintMode: (enabled) =>
            ({ setState, getState }) => {
                setState({
                    maintMode: enabled
                });
            },
        setDmEnabled: (enabled) =>
                ({ setState, getState }) => {
                    setState({
                        dmEnabled: enabled
                    });
                },
        setVersion: (version) =>
            ({ setState, getState }) => {
                setState({
                    appVersion: version
                });
            },

        fetchStatusChange: (data, count) =>
                ({ setState, getState }) => {
                    // mutate state synchronously
                    setState({
                        data: data,
                        fetchErrorCount: count,
                    });
                },
    },
    // optional, unique, mostly used for easy debugging
    name: 'app-state',
  });
  
  export const useAppState = createHook(Store);