import React from 'react';
import './_boilerplate.scss';

const Terms = () => {
    return (
        <div>
            <div className={`terms`}>                
                <ol>
                    <li>
                        This agreement (the "Agreement") sets forth the terms and conditions which apply to your use of the hashpoint.io web site (the "Site") which is provided by Hashpoint Research, LLC., (Hashpoint) and any subsequent memberships or accounts. Our aim is to provide our viewers with an online forum and we hope you'll enjoy the Site and find its services useful. Your use of the Site indicates your agreement to the following terms and conditions of service just as if you had submitted your acceptance. If you do not agree to be bound by the following terms and conditions of service, please discontinue your use of the Site. 
                    </li>

                    <li>
                        1. By creating an account, you certify that all information you provide in your registration is accurate, and to use the site in accordance with the lawful purpose for which it is intended.
                    </li>

                    <li>
                        2. Hashpoint does not provide refunds in whole or in part for any unused portion of a membership or account. In the event a user opts to disable or not use the account, user will continue to enjoy access to the Site at their account level until the account expires.
                    </li>

                    <li>
                        3. This Site is owned and operated by Hashpoint Research LLC., and its subsidiaries and contains material which is derived in whole or in part from material supplied and owned by Hashpoint and other sources, and is protected by copyright, trademark, and other applicable laws. You may not modify, copy, reproduce, republish, upload, post, transmit, publicly display, prepare derivative works based on, or distribute in any way any material from this Site including code and software ("Material"). You may download Material from this Site for your personal, non-commercial use only, provided you keep intact all copyright and other proprietary notices. In the event that you download Material from the Site, such Material is licensed to you by Hashpoint or its licensors and neither Hashpoint nor their licensors, transfer title to any such Material to you.
                    </li>

                    <li>
                        4. Data may be delayed as specified by data providers. Hashpoint furnishes this information without responsibility for accuracy and it is accepted by the Site viewer on the condition that error in transmission or omissions shall not be made the basis for any claim, demand or cause for action against Hashpoint or any of its information providers. Hashpoint believes its data and text services to be reliable, but accuracy is not warranted or guaranteed.
                    </li>
                    
                    <li>
                        5. The Site includes facts, views, opinions and recommendations of individuals and organizations deemed of interest. Hashpoint does not guarantee the accuracy, completeness or timeliness of, or otherwise endorse, these views, opinions or recommendations, give investment advice, or advocate the purchase or sale of any security or investment or any particular investment trading strategy.
                    </li>
                    
                    <li>
                        6. The Site may provide links to and/or may point to other internet sites that may be clients of Hashpoint or may be of interest to viewers of the Site. Hashpoint has no responsibility for these other internet sites and only provides this as a service to Site viewers. Hashpoint provides no warranties regarding the content of sites operated by firms which may compensate Hashpoint for listing on Hashpoint.io.
                    </li>
                    
                    <li>
                        7. The Site provides links to internet sites maintained by third parties ("third party sites") and may from time to time provide third party materials on the site. The materials on the site and the third party sites are provided 'as is' and without warranties of any kind, either express or implied. Hashpoint and its subsidiaries disclaim all warranties, express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose and non-infringement. Neither Hashpoint nor its subsidiaries warrant that the functions contained in the materials and products will be uninterrupted or error-free, that defects will be corrected, or that the site, including the forums, or the server that makes them available, are free of viruses or other harmful components. Neither Hashpoint nor its subsidiaries warrant or make any representations regarding the use or the results of the use of the materials available through the site or in third party sites in terms of their correctness, accuracy, timeliness, reliability or otherwise. You assume all risk of errors and/or omissions in using the site, including the transmission or translation of information. You assume full responsibility for implementing sufficient procedures and checks to satisfy the requirements for the accuracy and suitability of the service, including the information, and for maintaining any means which you require for the reconstruction of lost data or subsequent manipulations or analyses of the information provided hereunder. You (and not Hashpoint nor its subsidiaries) assume the entire cost of all necessary maintenance, repair or correction.
                    </li>
                    
                    <li>
                        8. In no event shall Hashpoint or its subsidiary companies, suppliers or partners be liable for any direct, indirect, punitive, incidental, special, consequential or other damages arising out of or in any way connected with the use of the site or with the delay or inability to use the site, or for any information, software, products and services obtained through the site, or otherwise arising out of the use of the site, whether based on contract, tort, strict liability or otherwise, even if Hashpoint, its subsidiary companies or any of its suppliers has been advised of the possibility of damages. You specifically acknowledge and agree that neither Hashpoint nor its subsidiary companies nor suppliers shall be liable for any defamatory, offensive or illegal conduct of any user of this service. Some states do not allow limitations on how long an implied warranty lasts, so the above limitation may not apply to you. Some states do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exculsion may not apply to you. This warranty gives you specific legal rights, and you may also have other rights which vary from state to state.
                    </li>
                    
                    <li>
                        9. You agree to defend, indemnify and hold harmless Hashpoint, its subsidiaries companies, and their respective directors, officers, employees and agents from and against any and all claims, actions, suits or proceedings, as well as any and all losses, liabilities, damages, costs and expenses (including reasonable attorneys fees) arising out of or accruing from (a) any material posted or otherwise provided by you that infringes any copyright, trademark, trade secret, trade dress, patent or other intellectual property right of any person or defames any person or violates their rights of publicity or privacy, (b) any misrepresentation made by you in connection with your use of the Site; (c) any non-compliance by you with the terms and conditions of this Agreement; and (d) claims brought by persons or entities other than the parties to this Agreement arising from or related to your access and use of the Site, including the information obtained through the Site.
                    </li>
                    
                    <li>
                        10. This Agreement and the license rights granted hereunder shall remain in full force and effect for the term of your subscription period unless terminated, suspended, or canceled for any of the following reasons: immediately by Hashpoint for any unauthorized access or use by you, including, without limitation concurrent access of the Site with identical user identification, permitting another person or entity to use your user identification to access the Site, or any other access or use of the Site except as expressly provided in this Agreement; or immediately, if you violate the terms and conditions of this Agreement or the rules and regulations relating to the use of, or tamper with or alter any of the software and/or data files contained in, or accessed through, the Site. Termination, suspension, or cancellation of this Agreement or your access rights shall not affect any right or relief to which Hashpoint or its subsidiary companies may be entitled, at law or in equity. Cancellation of your subscription by you is not permitted. There shall be no refunds upon termination, suspension or cancellation of this agreement. Upon termination of this Agreement, all rights granted to you will terminate and revert to Hashpoint and its licensors.
                    </li>
                    
                    <li>
                        11. All trademarks, service marks and trade names used on the Site are the property of Hashpoint or their respective owners, and may not be copied, downloaded or otherwise exploited without the permission of Hashpoint or the owner of such trademark, servicemark or trade name.
                    </li>
                    
                    <li>
                        12. If you have agreed to allow your minor child, or a child for whom you are legal guardian (a "Minor"), to register as a member of the Site, you agree that you shall be solely responsible for: the online conduct of such Minor; monitoring such Minor's access to and use of the Site; and the consequences of any use of the Site by such Minor.
                    </li>
                    
                    <li>
                        13. Hashpoint reserves the right to amend this Agreement, and to modify, add or discontinue any aspect, content, or feature of the Site. Such amendments, modifications, additions or deletions shall become effective upon notice thereof, which may be provided to you by posting on the Site, via e-mail or any other reasonable means. Continued use of the Site by you shall be deemed to indicate your acceptance of any such amendments, modifications, additions or deletions.
                    </li>
                    
                    <li>
                        14. Any and all disputes arising from this agreement shall be decided solely and exclusively by state or federal courts located in the State of Nevada. This agreement shall be governed by and construed in accordance with the laws of the State of Nevada without giving effect to any principles of conflicts of law. You may not assign any of your rights, obligations or privileges hereunder without the prior written consent of Hashpoint. Any assignment other than as provided for in this Section shall be null and void. If any provision of this agreement shall be deemed unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from this agreement and shall not affect the validity and enforceability of any remaining provisions. This Agreement and any posted operating rules constitute the entire agreement of the parties with respect to the subject matter hereof, and supersede all prior or contemporaneous communications and proposals, whether oral or written, between the parties with respect to such subject matter. No waiver by either party of any breach or default hereunder shall be deemed a waiver of any subsequent breach or default. The titles and subtitles used in this Agreement are used for convenience only and are not to be considered in construing or interpreting this Agreement.
                    </li>
                </ol>                
            </div>
        </div>
        
    );
  }

  export default Terms