import axios from 'axios';
import { getTimestamp, getBrowserIdentity, getMsMember, objectToQs, replaceAll } from './../shared/fn';

const funcsDir = () => {
    return '/.netlify/functions/';
}

const mirrorNodeUrl = (env) => {
    if(!env) env = 'mainnet';
    return `https://${env}.mirrornode.hedera.com/api/v1/`;
}


export const getTPS = async (env) => {
    try {
        const envUrl = mirrorNodeUrl(env);
        const url = `${envUrl}blocks?limit=1`;
        const response = await axios.get(url);
        return response;
    } catch(e) {
        console.info('axios error - getTPS');
        return e;
    }
}

export const getNetworkNode = async (gtNodeId, order) => {
    const endpoint = `network/nodes?limit=1&node.id=eq:${gtNodeId}`
    const response = await fetchEndpoint(endpoint);
    return response;
}

export const getNetworkNodes = async (gtNodeId, order) => {
    const nodeStr = gtNodeId===0 ? `&node.id=gte:${gtNodeId}` : `&node.id=gt:${gtNodeId}`;
    const endpoint = `network/nodes?limit=25&order=${order}${nodeStr}`
    const response = await fetchEndpoint(endpoint);
    return response;
}

export const getNetworkStake = async () => {
    const endpoint = `network/stake`;
    const response = await fetchEndpoint(endpoint);
    return response;
}

export const getAccount = async (accountId) => {
    const endpoint = `accounts/${accountId}`
    const response = await fetchEndpoint(endpoint);
    return response;
}

export const getAccountBalance = async (accountId) => {
    const endpoint = `balances?account.id=${accountId}`
    const response = await fetchEndpoint(endpoint);
    return response;
}

export const getNetworkSupply = async () => {
    const endpoint = `network/supply`
    const response = await fetchEndpoint(endpoint);
    return response;
}

export const fetchEndpoint = async (endpt) => {
    try {
        const url=`${mirrorNodeUrl() + endpt}`;
        const response = await axios.get(url);
        return response;
    } catch(e) {
        console.info(e);
        return e;
    }
}

export const fetchLocalData = async (name) => {
    try {
        const url=`${name}.json`;
        const response = await axios.get(url);
        return response;
    } catch(e) {
        console.info(e);
        //return e;
    }
}

export const getView = async(view) =>{
    const resp=await axios(`${funcsDir()}db_view_get?view=${view}`)
        .then(function (response) {
            return response;
    })

    return resp.data.data;
}

export const getStakingData = async() =>{
    const resp=await axios(`${funcsDir()}db_view_get?view=stakingdata`)
        .then(function (response) {
            return response;
    })

    return resp.data.data;
}

export const getHbarMeta = async() =>{
    const resp=await axios(`${funcsDir()}db_view_get?view=hbarmeta`)
        .then(function (response) {
            return response;
    })

    return resp.data.data;
}

export const getHbarDist = async() => {
    const resp=await axios(`${funcsDir()}db_view_get?view=hbardist`)
        .then(function (response) {
            return response;
    })

    return resp.data.data;
}

export const getAppSettings = async() => {
    const resp=await axios(`${funcsDir()}db_view_get?view=appsettings`)
        .then(function (response) {
            return response;
    })

    return resp.data.data;
}

export const getAdminData = async() => {
    const resp=await axios(`${funcsDir()}db_view_get?view=admindata`)
        .then(function (response) {
            return response;
    })

    return resp.data.data;
}

export const updateInvitation = async(invitationId, memberEmail) => {
    const resp=await axios(`${funcsDir()}invitation_update?invitationid=${invitationId}&memberemail=${memberEmail}`);    
    return resp.data.response;
}

export const deleteInvitation = async(invitationId, email, sessionState) => {
    const sessionId = sessionState.sessionId;
    const uniqueId = sessionState.data.customFields.uniqueid;
    let encoded = encodeURIComponent(email);

    const resp=await axios(`${funcsDir()}invitation_delete?invitationid=${invitationId}&uniqueid=${uniqueId}&sessionid=${sessionId}&email=${encoded}`);    
    return resp.data.response;
}

export const upsertInvitation = async(invitation, sessionState) => {
    const invitationId = invitation.invitationid ?? '';
    const email = encodeURIComponent(invitation.email);
    const firstname = invitation.firstname;
    const lastname = invitation.lastname;
    const sessionId = sessionState.sessionId;
    const uniqueId = sessionState.data.customFields.uniqueid;

    //console.log('session state', sessionState.data);
    let paramStr =`invitation_upsert?invitationid=${invitationId}&email=${email}&firstname=${firstname}&lastname=${lastname}&uniqueid=${uniqueId}&sessionid=${sessionId}`;
    let resp
    resp=await axios(`${funcsDir()}${paramStr}`)
         .then(function (response) {
             return response;
    })

    return resp.data.response;
}

export const deleteMessageTemplate = async(messageTemplateId, sessionState) => {
    const sessionId = sessionState.sessionId;
    const uniqueId = sessionState.data.customFields.uniqueid;

    const resp=await axios(`${funcsDir()}message_template_delete?messagetemplateid=${messageTemplateId}&uniqueid=${uniqueId}&sessionid=${sessionId}`);    
    return resp.data.response;
}

export const upsertGcNodes = async(record) => {
    const nodeId = record.nodeid ?? '';
    const owner = record.owner;
    const city = record.city;
    const iso = record.iso3;
    const geoLocationId=record.geolocationid;
    const sectorId = record.sectorid;
    const coordinates=record.coordinates;
    const dateJoined = new Date(record.datejoined).getTime()*1000000;
    const dateLeft = record.dateleft ? new Date(record.dateleft).getTime()*1000000 : '';
    const nodenum =record.nodenum;
    const isActive=record.isactive ? 'true' : 'false';
    
    const resp=await axios(`${funcsDir()}node_upsert?nodeid=${nodeId}&owner=${owner}&city=${city}&iso=${iso}&geolocationid=${geoLocationId}&sectorid=${sectorId}&coordinates=${coordinates}&datejoined=${dateJoined}&dateleft=${dateLeft}&nodenum=${nodenum}&isactive=${isActive}`)
        .then(function (response) {
            return response;
    })

    return resp.data.response;
}

export const deleteGcNode = async(nodeId, member) => {
    const permissionId = member.permissions[0];

    const resp=await axios(`${funcsDir()}node_delete?nodeid=${nodeId}&permissionid=${permissionId}`);    
    return resp.data.response;
}

export const upsertArticle = async(record, member) => {
    const articleId = record.article_id ?? '';
    const title = record.article_title ?? '';
    const content = encodeURIComponent(record.article_content) ?? '';
    const dateCreated = record.article_datecreated ? getTimestamp(record.article_datecreated) : getTimestamp();
    const isActive = record.article_isactive;   //?.toString()==='true' ? 'true' : 'false';
    const datePublished = record.article_datepublished ? getTimestamp(record.article_datepublished) : isActive ? getTimestamp() : '';
    const permissionId = member.data.permissions[0];

    const resp=await axios(`${funcsDir()}articles_upsert?articleid=${articleId}&title=${title}&content=${content}&datecreated=${dateCreated}&datepublished=${datePublished}&permissionid=${permissionId}&isactive=${isActive}`);
    return resp.data.response;
}

export const getArticles = async(sessionId, permissionsId, limit) => {
    if(!limit) {
        limit = 1000000;
    }
    
    const resp=await axios(`${funcsDir()}articles_get?sessionid=${sessionId}&permissionid=${permissionsId}&limit=${limit}`);
    return resp.data.response;    
};

export const deleteArticle = async(record, sessionState) => {
    const articleId = record[0].article_id;
    const uniqueId = sessionState.data.customFields.uniqueid;
    const sessionId = sessionState.sessionId;
    
    const resp=await axios(`${funcsDir()}article_delete?articleid=${articleId}&uniqueid=${uniqueId}&sessionid=${sessionId}`);
    
    return resp.data.response;
};

export const getFoundations = async(sessionId) => {
    const resp=await axios(`${funcsDir()}db_view_get?view=foundations&sessionid=${sessionId}`)
        .then(function (response) {
            return response;
    })

    return resp.data.data;    
};

export const saveFoundation = async(sessionId, member, title, content, datecreated, datepublished, active, foundationid) => {
    const resp=await axios(`${funcsDir()}foundations_upsert?sessionid=${sessionId}&title=${title}&content=${content}&datecreated=${datecreated}
        &datepublished=${datepublished}&active=${active}&foundationid=${foundationid}&permissionid=${member.permissions[0]}`)
         .then(function (response) {
             return response;
    })

    return resp;
};

export const getNetworkFees = async () => {
    const res=await axios(`${funcsDir()}udf_networkfees`)
        .then(function (response) {
            return response;
    })
    .catch(function (error) {
        return {
            statusCode: 500,
            body: `Error: ${error}`
        }
    });

    return res;   
}

export const getRevenueAllocation = async () => {
    const res=await axios(`${funcsDir()}network_stake_get`)
        .then(function (response) {
            return response;
    })

    return res;   
}

export const getAccountGroupSupply = async () => {
    const resp=await axios(`${funcsDir()}hbar_supply_get`);
    return resp.data.balances;
}

export const getAccountsByGroup = async(member, sessionId) => {
    const resp=await axios(`${funcsDir()}accounts_by_group_get?sessionid=${sessionId}&permissions=${member.permissions[0]}`)
        .then(function (response) {
            return response;
    })

    return resp;
};

export const getAccountGroups = async() => {
    const resp=await axios(`${funcsDir()}db_view_get?view=accountgroups`)
        .then(function (response) {
            return response;
    })

    return resp;
};

export const saveAccountGroup = async(ag) => {
    const accountGroupId = ag.accountgroupid;
    const accountGroupName = ag.accountgroupname;
    const resp=await axios(`${funcsDir()}account_group_upsert?accountgroupid=${accountGroupId}&accountgroupname=${accountGroupName}`)
        .then(function (response) {
            return response;
    })

    return resp;
};

export const saveFeedback = async(comment, section, memberid) => {
    const resp=await axios(`${funcsDir()}feedback_insert?msmemberid=${memberid}&comment=${comment}&section=${section}`)
         .then(function (response) {
             return response;
    })

    return resp;
};

export const saveAccount = async(account) => {
    const accountId = account.accountid;
    const accountGroupId = account.accountgroupid;
    const shard = account.shard;
    const realm = account.realm;
    const num = account.num;
    const alias = account.alias;

    const resp=await axios(`${funcsDir()}account_upsert?accountid=${accountId}&accountgroupid=${accountGroupId}&shard=${shard}&realm=${realm}&num=${num}&alias=${alias}`)
        .then(function (response) {
            return response;
    })

    return resp;
};

export const deleteAccount = async(accountId) => {
    const resp=await axios(`${funcsDir()}account_delete?accountid=${accountId}`)
        .then(function (response) {
            return response;
    })

    return resp;
};

export const deleteAccountGroup = async(accountGroupId) => {
    const resp=await axios(`${funcsDir()}account_group_delete?accountgroupid=${accountGroupId}`)
        .then(function (response) {
            return response;
    })

    return resp;
};

export const deleteMemberAccount = async(member, memberAccountId) => {
    const uniqueid = member.customFields.uniqueid;
    const resp=await axios(`${funcsDir()}member_account_delete?memberaccountid=${memberAccountId}&uniqueid=${uniqueid}`)
        .then(function (response) {
            return response;
    })

    return resp;
};
export const upsertMemberAccount = async (member, rec) => {
    const uniqueid = member.customFields.uniqueid;
    const memberAccountId=rec.memberaccountid ? rec.memberaccountid : '';
    const shard=rec.shard;
    const realm=rec.realm;
    const num=rec.num;
    const paramStr=`memberaccountid=${memberAccountId}&uniqueid=${uniqueid}&shard=${shard}&realm=${realm}&num=${num}`
    
    const res=axios(`${funcsDir()}member_account_upsert?${paramStr}`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
        
    return res;
}

export const createMember = async (id, authEmail, dateCreated) => {        
    const paramStr=`msmemberid=${id}&authemail=${authEmail}&datecreated=${dateCreated}`
    
    const res=axios(`${funcsDir()}member_create?${paramStr}`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
        
    return res;
}

export const getMemberAccounts = async (member, sessionId='') => {
    const uniqueid = member.customFields.uniqueid;
    const res=axios(`${funcsDir()}member_accounts_get?uniqueid=${uniqueid}&sessionid=${sessionId}`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
        
    return res;
}

export const getInvitation = async (email, sessionId='') => {
    email = encodeURIComponent(email);

    const res=axios(`${funcsDir()}invitations_get?email=${email}&sessionid=${sessionId}`)
        .then(function (response) {
            return response.data.response[0];
            
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
        
    return res;
}

export const getAccountsRewards = async (member, sessionId) => {
    const uniqueid = member.customFields.uniqueid;
    const res=axios(`${funcsDir()}accounts_rewards_get?uniqueid=${uniqueid}&sessionid=${sessionId}`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
        
    return res;
}

export const insertMember = async (member) => {
    const browser = getBrowserIdentity();
    
    let tempStr = objectToQs(member)
        .map(([[key0, ...keysRest], value]) =>
            `${key0}${keysRest.map(a => `_${a}`).join('')}=${value}`)
        .join('&');

    tempStr = replaceAll(tempStr, 'planConnections[0]', 'plan');

    let paramStr =`${tempStr}&browser=${browser}`;
    
    //console.log('paramStr', paramStr)
    const res=await axios(`/.netlify/functions/member_insert?${paramStr}`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            logError(error.statusCode, error.message, member.id);
            return {
                statusCode: error.statusCode,
                body: `Error: ${error}`
            }
        });
    
        return res;
}

export const updateMember = async (data) => {
    if(!data) return;

    let uniqueid='';
    let permissions='';
    let username='';
    let firstname = '';
    let lastname = '';
    let email = '';

    if(data) {
        uniqueid = data.customFields.uniqueid ? data.customFields.uniqueid.trim() : '';
        permissions = data.permissions?.length===0 ? '' : data.permissions[0].trim();
        username = data.customFields.username ? data.customFields.username.trim() : '';
        firstname = data.customFields.firstname ? data.customFields.firstname.trim() : '';
        lastname = data.customFields.lastname ? data.customFields.lastname.trim() : '';
        email = data.auth.email.trim();
    }
    
    let paramStr=`uniqueid=${uniqueid}&authemail=${email}&firstname=${firstname}&lastname=${lastname}&permissions=${permissions}&username${username}&verified=${data.verified}`;
    
    const res=await axios(`/.netlify/functions/member_update?${paramStr}`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            logError(error.statusCode, error.message, data.id);
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
    
        return res;
}

export const updateMemberPlan = async (member) => {
    const uniqueId = member.customFields.uniqueid;
    const plan = member.planConnections[0];
    const payment = plan?.payment;

    if(!uniqueId || !plan || !payment) return;

    //const permissions = member.permissions[0];
    //const stripeCustomerId = member.stripeCustomerId;
    const msPlanId = plan.id;
    const planActive = plan.active.toString();
    const planId = plan.planId;    
    const planStatus = plan.status;
    const planType = plan.type;
    const priceId = payment.priceId;
    const priceStatus = payment.status;
    const amount = payment.amount;
    const currency = payment.currency;
    const cancelDate = payment.cancelAtDate ?? '';
    const nextBillingDate=payment.nextBillingDate ?? '';
    const lastBillingDate=payment.lastBillingDate ?? '';

    let paramStr=`uniqueid=${uniqueId}&msplanid=${msPlanId}&planid=${planId}&planactive=${planActive}&planstatus=${planStatus}&plantype=${planType}&priceid=${priceId}&pricestatus=${priceStatus}&amount=${amount}&currency=${currency}&datecancel=${cancelDate}&nextbillingdate=${nextBillingDate}&lastbillingdate=${lastBillingDate}`;
    
    const res=await axios(`/.netlify/functions/member_plan_update?${paramStr}`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            logError(error.statusCode, error.message, uniqueId);
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
    
        return res;
}

export const updateMemberVerification = async (uniqueId, verified) => {
    if(!verified) return;   
    
    let paramStr=`uniqueid=${uniqueId}&verified=${verified.toString()}`;
    
    const res=await axios(`${funcsDir()}member_verification_update?${paramStr}`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            logError(error.statusCode, error.message, uniqueId);
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
    
        return res;
}

export const getMember = async (id) => {
    const res=axios(`${funcsDir()}member_get?msmemberid=${id}`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
        
    return res;
}

export const insertPublishedArticleDms = async (articleId, uniqueId, sessionId) => {
    let paramStr=`uniqueid=${uniqueId}&sessionid=${sessionId}&articleid=${articleId}`;
    
    const res=await axios(`/.netlify/functions/member_dms_article_insert?${paramStr}`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            logError(error.statusCode, error.message, paramStr);
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
    
        return res;
}

export const insertMemberDm = async (uniqueId, fromUniqueId, message, subject, messageId, memberId) => {

    if(!messageId) {
        messageId = '';
    }

    if(!message) {
        message = '';
    }

    if(!subject) {
        subject = '';
    }

    let paramStr=`uniqueid=${uniqueId}&fromuniqueid=${fromUniqueId}&messageid=${messageId}&message=${message}&subject=${subject}`;
    
    const res=await axios(`/.netlify/functions/member_dm_insert?${paramStr}`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            logError(error.statusCode, error.message, paramStr);
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
    
        return res;
}

export const updateDmStatus = async(memberDmId, uniqueId, statusId) => {
    await axios(`${funcsDir()}member_dm_status_update?memberdmid=${memberDmId}&uniqueid=${uniqueId}&statusid=${statusId}`);
}

export const getMemberUnreadDmCount = async(uniqueId) => {
    if(!uniqueId) return;

    const unreadCount = await axios(`${funcsDir()}member_dm_count_get?uniqueid=${uniqueId}`);
    return unreadCount;
}

export const getMemberDirectMessages = async(uniqueId) => {
    const data = await axios(`${funcsDir()}member_dm_get?uniqueid=${uniqueId}`);
    return data;
}

export const upsertAdminData = async(record) => {
    const dataName = record.dataname;
    const dataValue = record.datavalue;
    
    const resp=await axios(`${funcsDir()}admin_data_upsert?dataname=${dataName}&datavalue=${dataValue}`)
        .then(function (response) {
            return response;
    })

    return resp;
}

export const createUserSession = async (data, tokenId) => {
    if(!tokenId) tokenId='';

    const uniqueId = data.customFields.uniqueid;
    const browser = getBrowserIdentity();
    const paramStr=`uniqueid=${uniqueId}&tokenid=${tokenId}&browser=${browser}`;
    
    const res=axios(`${funcsDir()}session_insert?${paramStr}`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
    return res;
}

export const expireUserSession = async (sessionId) => {
    if(!sessionId) return;

    const paramStr=`sessionid=${sessionId}`;    
    const res=axios(`${funcsDir()}session_update?${paramStr}`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
    return res;
}

export const getSession = async (uniqueId) => {
    const res=axios(`${funcsDir()}session_get?uniqueid=${uniqueId}`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
        
    return res;
}

export const getLatency = async () => {
    const res=axios(`${funcsDir()}latency_get`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
        
    return res;
}

export const trackEvent = (location, session, resourceId) => {
    //don't log clicks on ms protected pages if user is not logged in
    location = location.replace("/", "");
    if(location !== 'dashboard' && !session && !resourceId) return;

    const sessionId = session ? session : '';
    const resourceUuid = resourceId ? resourceId : '';

    const res=axios(`${funcsDir()}appevent_insert?location=${location}&sessionid=${sessionId}&resourceuuid=${resourceUuid}`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });

    return res;
}

export const logError = async (errorCode, errorMessage, errorDetail, msMemberId, sessionId) => {
    if(!msMemberId) {
        const member=getMsMember();
        msMemberId = (member && member.id) ? member.id : '';
    }
    
    if(!sessionId) {
        sessionId=localStorage.getItem('__sak:session') ?? '';
    }

    const paramStr=`errorCode=${errorCode}&errorMessage=${errorMessage}&errorDetail=${errorDetail}&msmemberid=${msMemberId}&sessionid=${sessionId}`
    const res=axios(`${funcsDir()}error_insert?${paramStr}`)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });
    return res;
}

export const send = async(subject, html) => {
    const res=axios(`${funcsDir()}send_test?subject=${subject}&html=${html}`)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });

        return res;
}

export const unsubscribe = async(email) => {
    const res=axios(`${funcsDir()}unsubscribe?email=${email}`)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return {
                statusCode: 500,
                body: `Error: ${error}`
            }
        });

        return res;
}

export const fetchAccessToken = async() => {
    const res=await axios(`/.netlify/functions/pbi_access_token_get`)
        .then(function (response) {
            return response;
        })   

    return res;
};

export const fetchEmbedToken = async(groupId, reportId, accessToken) => {
    const res=await axios(`/.netlify/functions/pbi_embed_token_get?groupid=${groupId}&reportid=${reportId}&accesstoken=${accessToken}`)
        .then(function (response) {
            return response;
        })

    return res.data.token;

};
