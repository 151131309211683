import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom'
import { Form, Field } from 'react-final-form';
import { useMemberstack } from "@memberstack/react";
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { OverlayPanel  } from 'primereact/overlaypanel';
//import { useSession } from '../stores/useSession';
import { classNames } from 'primereact/utils';
import Terms from '../boilerplate/terms';
import * as api from '../../api/api';
import { uuidv4, getHslFromString, left } from '../../shared/fn';
import * as cnst from '../../shared/constants';
import './_forms.scss';

import {
    useQueries,
} from '@tanstack/react-query';

const UpdateAuthForm = (props) => {    
    const [formMode, setFormMode] = useState('edit');
    const [errorMessage, setErrorMessage] = useState(null);
    const [accept, setAccept] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [password, setPassword] = useState(null);
    const [oldPassword, setOldPassword] = useState(null);
    const [email, setEmail] = useState(null);
    const [inviteDbData, setInviteDbData] = useState(null);
    // const [xxx, setXxx] = useState();

    //const [state, actions] = useSession();
    const memberstack = useMemberstack();
    const navigate = useNavigate();
    const beenHereRef = useRef(null)
    const currentMemberRef = useRef(null)

    const { closeDialog } = props;

    const op = useRef(null);
    const op2 = useRef(null);

    const getInvitation = useCallback(async (qk) => {
        const email = qk.queryKey[1];
        const data=await api.getInvitation(email)
            .then(function (response) {
                return response;
            });

        return data;
    }, []);

    const responses = useQueries({
        queries: [
           { 
                queryKey: ['get-invitation-data', email], 
                queryFn: getInvitation, 
                enabled: email !== null 
            },
        ],
    });

    const openTooltip =(e) => {
        op.current.toggle(e);
    }
    
    const openTooltip2 =(e) => {
        op2.current.toggle(e);
    }

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic
    }

    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const handleBackClick = () => {
        setFormMode('edit');
        setErrorMessage(null);
        setDisabled(false);
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Close" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" autoFocus/>
            </div>
        );
    }

    const handleInvitationResponse = useCallback((data) => {
        //console.log('handleInvitationResponse', data)
        setInviteDbData(data);
    }, []);

    const getCurrentMember = useCallback(async() => {
        if(beenHereRef.current) return;
        beenHereRef.current = true;

        await memberstack.getCurrentMember()
            .then(async({ data }) => {
                if(data) {
                    currentMemberRef.current = data;                    
                    setEmail(data.auth.email);                    
                }
            })
            .catch();
    }, [ memberstack ]);

    useEffect(() => {        
        if(responses[0].status==="success") {
            handleInvitationResponse(responses[0].data);
        }

    }, [ responses, handleInvitationResponse ]);

    useEffect(() => {
        if(currentMemberRef.current) return;       //we've already fetched current user

        getCurrentMember();

    }, [ getCurrentMember ]);

    const validate = (data) => {
        
        if(data?.firstname)
            localStorage.setItem('__sak_regform_firstname', data.firstname?.trim());
        
        if(data?.lastname)
            localStorage.setItem('__sak_regform_lastname', data.lastname?.trim());
        
        if(data?.email)
            localStorage.setItem('__sak_regform_email', data.email?.trim());

        if(!formMode==='edit') return;

        let errors = {};

        if (!data.firstname) {
            errors.firstname = ' required';
        }

        if (!data.lastname) {
            errors.lastname = ' required';
        }

        // if (!data.username) {
        //     errors.username = ' required.';
        // }

        if (!data.email) {
            errors.email = ' required';
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
            errors.email = 'Invalid email address. E.g. example@email.com';
        }

        if (!data.password) {
            errors.password = ' required';
        }

        if (! /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,20}$/gm.test(data.password)) {
            errors.password = ' invalid';
        }
        else {
            setPassword(data.password);
        }

        if (!data.old_password || data.old_password.length < 8) {
            errors.old_password = ' required';
        }
        else if(data.old_password !== inviteDbData?.temppassword) {
            errors.old_password = ' no password match';
        }
        else if(data.password === inviteDbData?.temppassword) {
            errors.password = ' password is not new';
        }
        else {
            setOldPassword(data.old_password);
        }

        if (!data.password) {
            errors.password = ' required';
        }
        else if(data.password.length < 8) {
            errors.password = ' pwd too short';
        }
        else {
            setPassword(data.password);
        }

        if (!data.accept) {
            errors.accept = ' required';
        }

        return errors;
    };

    const onSubmit = async (data, form) => {              
        if(formMode==='edit'){
            setFormMode('review');
            return;
        }

        setSpinnerVisible(true);

        try {
            const uniqueId = uuidv4();
            const init = left(data?.firstname?.trim(), 1)
            const hsl = getHslFromString(init);
            let msMember;
            let authResponse;
            
            setErrorMessage(null);

            if(data.email === inviteDbData?.email) {
                setDisabled(true);
                setErrorMessage(`Could not update your profile. '${data.email}' is not a valid email address.`);
                setSpinnerVisible(false);
                return;
            }

            if(data.old_password !== inviteDbData?.temppassword) {
                setDisabled(true);
                setErrorMessage(`Could not update your profile. '${data.old_password}' is not the current password.`);
                setSpinnerVisible(false);
                return;
            }

            if(data.password === inviteDbData?.temppassword) {
                setDisabled(true);
                setErrorMessage(`Could not update your profile. '${data.password}' is your current password and cannot be reused.`);
                setSpinnerVisible(false);
                return;
            }

            if(errorMessage) return;

            try {
                authResponse = await memberstack.updateMemberAuth({
                    oldPassword: data.old_password.trim(),
                    newPassword: password.trim(),
                    email: data.email.trim()
                 })
            }
            catch (ex) {
                let errText;
                if(ex.code==='invalid-password') {
                    errText = `Password does not match account password`;
                }

                setDisabled(true);
                setErrorMessage(`Could not update your profile. ${errText}`);
                setSpinnerVisible(false);
                console.log('ex', ex);
                return;
            }
            
            if(!errorMessage ?? authResponse.data?.auth.email === data.email.trim()) {
                msMember = await memberstack.updateMember({
                    customFields: {
                        firstname: data.firstname,
                        lastname: data.lastname,
                        uniqueid: uniqueId,
                        hsl: hsl,
                        submitfeedback: '1',
                        blocked: '0',
                        dbmbrid: '0'
                    }
                })
            }

            if(msMember) {
                closeDialog(false);
                
                api.updateInvitation(inviteDbData.invitationid, data.email);
                navigate("/verify", { state: { }, replace: true });
            }            
        }
        catch(err) {
            setErrorMessage(err.message.replace('The provided email is already taken.', 'This email address is already registered.'));
            setSpinnerVisible(false);
            setFormMode('error');

            if(!err) {
                closeDialog();
            }
        }

        form.restart();
    };

    const isFormFieldValid = (meta) => !!(meta.touched && meta.error);

    //const passwordHeader = <h6>{``}</h6>;
    const passwordFooter = (
        <React.Fragment>
            <p className="mt-2 mb-1">Password requirements</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>At least one lowercase character</li>
                <li>At least one uppercase character</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
                <li>Maximum 20 characters</li>
            </ul>
        </React.Fragment>
    );

    const oldPasswordFooter = (
        <>
            <p className="mt-2 mb-1">Enter the temporary password.</p>
        </>
    );

    return (
        <div style={{width: '100%'}}>
            <Form 
                onSubmit={onSubmit}
                initialValues={{
                    firstname: localStorage.getItem('__sak_regform_firstname') ?? '', 
                    lastname: localStorage.getItem('__sak_regform_lastname') ?? '',
                    //username: (customFields && customFields.username) ?? localStorage.getItem('__sak_regform_username') ?? '',  
                    email: localStorage.getItem('__sak_regform_email') ?? '', 
                    password: password,
                    old_password: oldPassword,
                    accept: accept 
                }} 
                validate={validate} 
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className={`${formMode}-mode p-fluid pt-1`}>
                        <ProgressSpinner 
                            style={{position: 'absolute', top: '50%', width: '50%', height: '10%', transform: 'translate(-50%, -50%)'}}
                            className={`${spinnerVisible ? '' : 'hidden'}`}
                        />

                        <div className={`form-message ${formMode==='edit' && errorMessage ? '' : 'hidden'}`}>
                            {errorMessage}
                        </div>

                        <div className={`form-message ${formMode==='review' && errorMessage ? '' : 'hidden'}`}>
                            {errorMessage}
                        </div>

                        <div className={`form-message ${formMode==='review' ? 'review-message' : 'hidden'}`}>                            
                               Please review and click 'Update' to complete your profile.
                        </div>

                        <div className={`flex justify-content-center gap-3 ${formMode==='review' ? 'mt-0' : ''}`}>
                            <Field name="firstname" render={({ input, meta }) => (
                                <div className="field firstname">
                                    <span className="">
                                        <label 
                                            htmlFor="firstname" 
                                            className={`form-label ${classNames({ 'p-error': isFormFieldValid(meta) })}`}
                                            style={{}}
                                        >
                                            {cnst.LBL_FIRST_NAME}: <span className='asterisk'>*</span>
                                        </label>&nbsp;
                                        <InputText 
                                            id="firstname" 
                                            {...input} 
                                            autoFocus={ true }
                                            disabled={false}
                                            readOnly={formMode==='review'}
                                            className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                            minLength={2}
                                            name='firstname'
                                            style={{width: '20rem'}}
                                        />
                                    </span>
                                </div>
                            )} />
                        </div>
                        
                        <div className={`flex justify-content-center gap-3 ${formMode==='review' ? 'mt-0' : ''}`}>
                            <Field name="lastname" render={({ input, meta }) => (
                                <div className="field lastname">
                                    <span className="">
                                        <label htmlFor="lastname" className={`form-label ${classNames({ 'p-error': isFormFieldValid(meta) })}`}>
                                            {cnst.LBL_LAST_NAME}: <span className='asterisk'>*</span>
                                        </label>&nbsp;
                                        <InputText 
                                            id="lastname" 
                                            {...input}
                                            disabled={false}
                                            readOnly={formMode==='review'}
                                            className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                            minLength={2}
                                            data-ms-member='lastname'
                                            style={{width: '20rem'}}
                                        />
                                    </span>
                                </div>
                            )} />
                        </div>

                        <div className={`flex justify-content-center gap-3 ${formMode==='review' ? 'mt-0' : ''}`}>
                            <Field name="email" 
                                render={({ input, meta }) => (
                                <div className="field email">
                                    <span className="">
                                        <label htmlFor="email" className={`form-label ${classNames({ 'p-error': isFormFieldValid(meta) })}`}>
                                            {cnst.LBL_EMAIL}: <span className='asterisk'>*</span>
                                        </label>&nbsp;
                                        <InputText 
                                            id="email" 
                                            {...input}
                                            disabled={false}
                                            readOnly={formMode==='review'}
                                            className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                            style={{width: '20rem'}}
                                        />
                                        
                                    </span>
                                </div>
                            )} />
                        </div>

                        <div className={`flex justify-content-center gap-3 ${formMode==='review' ? 'mt-0' : ''}`}>
                            <Field name="old_password" 
                                render={({ input, meta }) => (
                                <div className="field password">
                                    <span className="">
                                        <label htmlFor="old_password" className={`form-label ${classNames({ 'p-error': isFormFieldValid(meta) })}`}>
                                            Old {cnst.LBL_PASSWORD}: <span className='asterisk'>*</span>
                                        </label>&nbsp;
                                        <InputText 
                                            id="old_password" 
                                            {...input}
                                            disabled={false}
                                            readOnly={formMode==='review'}
                                            className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                            style={{width: '20rem'}}
                                        />
                                        <i className="pi pi-info-circle hand"                                             
                                            data-pr-position="right" 
                                            onClick={(e) => openTooltip2(e)}
                                            style={{ marginLeft: '.5rem', position: 'absolute', left: '37.25rem', visibility: formMode==='review' ? 'hidden' : 'visible' }}
                                        />

                                        <OverlayPanel ref={op2} >
                                            <div className={`tooltip-content`} style={{lineHeight: '1.25rem', margin: '0'}}>
                                                {oldPasswordFooter}
                                            </div>
                                        </OverlayPanel>
                                    </span>
                                </div>
                            )} />
                        </div>

                        <div className={`flex justify-content-center gap-3 ${formMode==='review' ? 'mt-2' : ''}`}>
                            <Field name="password" 
                                render={({ input, meta }) => (
                                <div className={`field password`}>
                                    <span className={`${formMode==='edit' ? 'p-input-icon-right flex align-items-center' : 'flex'}`}>
                                        <label htmlFor="password" 
                                            className={`form-label ${classNames({ 'p-error': isFormFieldValid(meta) })}`}
                                        >
                                            New {cnst.LBL_PASSWORD}: <span className='asterisk'>*</span> 
                                            
                                        </label>
                                        <span className={`${formMode==='review' ? '' : 'hidden'}`} style={{paddingLeft: '0rem'}}>
                                            <InputText 
                                                id="review_password" 
                                                {...input}
                                                disabled={false}
                                                readOnly={formMode==='review'}
                                                className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                                minLength={2}
                                                //data-ms-member='password'
                                                style={{width: '9rem', position: 'relative', top: '-.75rem', left:'.5rem'}}
                                            />
                                        </span>
                                        
                                        <Password 
                                            id="password" 
                                            {...input}
                                            toggleMask = { true }
                                            disabled={false}
                                            readOnly = {formMode==='review'}
                                            //className={classNames({ 'p-invalid': isFormFieldValid(meta) })}                                            
                                            panelStyle={{background:'#37393f'}}
                                            mediumRegex
                                            feedback={true}
                                            maxLength={20}
                                            style = {{width: '20rem', visibility: formMode==='review' ? 'hidden' : 'visible'}}
                                        />

                                        <i className="pi pi-info-circle hand" 
                                            data-pr-tooltip={passwordFooter.toString()}
                                            data-pr-position="right" 
                                            onClick={(e) => openTooltip(e)}
                                            style={{ marginLeft: '.5rem', position: 'absolute', left: '28rem', visibility: formMode==='review' ? 'hidden' : 'visible' }}
                                        />

                                        <OverlayPanel ref={op} >
                                            <div className={`tooltip-content`} style={{lineHeight: '1.25rem', margin: '0'}}>
                                                {passwordFooter}
                                            </div>
                                        </OverlayPanel>
                                    </span>
                                </div>
                            )} />
                        </div>

                        <div className={`flex justify-content-center gap-3 ${formMode==='review' ? 'mt-0' : ''}`}>
                            <Field name="accept" type="checkbox" render={({ input, meta }) => (
                                <div className={`field-checkbox ${formMode==='review' ? 'hidden' : ''}`}>
                                    <Checkbox 
                                        inputId="accept"
                                        onChange={e => setAccept(e.checked)}
                                        {...input} 
                                        className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                        disabled={formMode==='review'}
                                        //checked={accept}
                                    />
                                    &nbsp;&nbsp;
                                    {cnst.LBL_READ_TERMS}&nbsp;<Link onClick={() => onClick('displayBasic')} className={`link`}>{cnst.LBL_TERMS_CONDITIONS}</Link>
                                    <label 
                                        htmlFor="accept" 
                                        className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                                    </label>
                                </div>
                            )} />
                        </div>

                        <div>
                            &nbsp;
                        </div>

                        <div className={`faux-form-footer`}>
                            <Button 
                                type="button" 
                                label="Cancel" 
                                onClick={(e) => closeDialog()} 
                                className="mt-0 p-button-secondary"
                                disabled = {false}
                                visible={true}
                            />
                            &nbsp;&nbsp;  
                            <Button 
                                type="button" 
                                label="Back" 
                                onClick={(e) => handleBackClick('edit')} 
                                className="mt-0 p-button-secondary"
                                disabled = {formMode==='edit' ? true : false}
                                visible={formMode==='edit' ? false : true}
                            />
                            &nbsp;&nbsp;
                            <Button 
                                type="submit" 
                                label={formMode==='edit' ? "Review" : "Update"} 
                                className="mt-0 p-button-primary" 
                                disabled = {(formMode==='edit' ? false : disabled)}
                            />
                        </div>                        
                    </form>                    
            )} />

            <Dialog 
                header={cnst.LBL_TERMS_CONDITIONS}
                visible={displayBasic} 
                style={{ width: '60vw' }} 
                footer={renderFooter('displayBasic')} 
                onHide={() => onHide('displayBasic')}
            >
                <div><Terms /></div>
            </Dialog>
        </div>

    );
  }

  export default UpdateAuthForm